.fund {
    background-color: rgba(170, 86, 229, 0.05);
    padding: 1rem 1rem;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.data_ride_user {
    display: flex;
    flex-direction: column;
}

.price_container {
    display: flex;
    gap: 2.5rem;
}

.price_container p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    color: #6356E5;
}