.main-container {
    max-width: 1280px;
    height: 100%;
}

.main-container h1 {
    margin-top: 2rem;
    text-align: left;
    color: #222B45;
    font-weight: 500;
}

.form {
    height: 100%;
}

.form .input {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: .9rem;
    justify-content: space-between;
    background-color: white;
    border-radius: 15px;
    padding: 1rem 2rem;
    filter: drop-shadow(0px 7px 22px rgba(0, 0, 0, 0.051));
}

.form .input input {
    border: none;
    color: #8F9BB3;
    font-size: 1rem;
    width: 100%;
    text-overflow: ellipsis;
    font-family: 'Poppins';
}

.form .input p {
    color: #222B45;
    font-weight: 500;
    font-size: 1rem;
    text-overflow: ellipsis;
    font-family: 'Poppins';
}

.form .input svg {
    background-color: rgba(170, 86, 229, 0.08);
    width: 2.5rem;
    height: 2.5rem;
    color: rgba(99, 86, 229, 1);
    border-radius: 10px;
    padding: .4rem;
}

.form .input .results {
    display: flex;
    flex-direction: column;
}

.form .input .res {
    position: relative;
}

.form .input .res .options {
    position: absolute;
    background-color: white;
    margin-top: 0.7rem;
    margin-left: -2rem;
    width: 25rem;
    z-index: 99;
    padding: 1rem;
}

@media screen and (max-width: 768px) {
    .form .input .res .options {
        width: auto;
    }
}

.form .input .res .options p {
    cursor: pointer;
}

.buton {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #6356E5;
    border: none;
    border-radius: 10px;
    align-items: center;
    font-weight: 400;
    cursor: pointer;
    font-family: 'Poppins';
    color: white;
    font-size: 1.4rem;
    margin-bottom: 15vh
}