body .app>header:nth-child(1) {
    padding: 24px 24px 40px;
}

h1 {
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 28px;

    color: #6356e5;
}

p {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 18px;
    opacity: 0.53;
    color: #222B45;
}

p.large {
    font-size: 24px;
    line-height: 21px;
    color: #000000;
}

.front-car-icon {
    top: 153px;
    left: 59px;
    width: 64px;
    height: 64px;
    object-fit: cover;
}

button {
    /* Layout Properties */
    /* UI Properties */
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    /* box-shadow: 0px 12px 35px #0000000A; */
    /* border-radius: 34px; */
    opacity: 100%;
}

modal {
    /* Layout Properties */
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 35px #0000000A;
    border-radius: 34px;
    opacity: 100%;
}

#pin-user {
    position: absolute;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    background-color: #6356E5;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.search-bar {
    position: fixed;
    top: 0;
    left: 0;
}

#pin-user::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    z-index: -1;
    margin-left: -.7rem;
    background-color: #a956e531;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    animation: radar 1.5s linear infinite;
}



@keyframes radar {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(3);
        opacity: 0;
    }
}

#tag-super {
    z-index: 9;
    position: fixed;
    top: 0;
    padding-top: 5px;
    color: white;
    left: 0;
    width: 100%;
    height: 3rem;
    text-align: center;
    background-color: #6356E5;
}

#options {
    z-index: 9999;
    position: fixed;
    top: 6rem;
    color: white;
    left: 0;
    width: 100%;
    height: 3rem;
    text-align: center;
}

#options .option {
    margin-top: 0rem;
    background: #FFFFFF;
    border-radius: 0 0 5px 5px;
    padding: 1px 2rem;
    cursor: pointer;
}


.search-bar-map {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    filter: drop-shadow(0px 7px 22px rgba(0, 0, 0, 0.051));
    border-radius: 5px;
    justify-content: space-between;
    display: flex;
    height: 6rem;
    justify-content: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    padding: 0 20%;
    flex-wrap: nowrap;
    background: #FFFFFF;
    border-bottom: 1px solid gray;
}
.searchbox{
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    background-color: #aa56e514;
    border-radius: 10px;
}

.closebox{
   margin-left: 1rem;
   margin-right: 1rem;
}

.search-bar-map .first-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.search-bar-map .options {
    position: relative;
    /* margin-top: 6rem; */
    display: flex;
    flex-direction: column;
}


.search-bar-map input {
    border: none;
    font-size: 1rem;
    padding-left: 2rem;
    font-family: 'Poppins';
    font-weight: 500;
}

.search-bar-map .icon-search {
    padding: 1.2rem;
    border-radius: 10px;
    margin-right: 2rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
    background: rgba(170, 86, 229, 0.08);
    display: flex;
    justify-content: center;
    width: 3rem;
    border: none;
    cursor: pointer;
}

.search-bar-map .icon-search svg {
    opacity: 1;
    color: #6356E5;
}



.ride-options {
    height: 16rem;
    position: fixed;
    bottom: 0rem;
    filter: drop-shadow(0px 7px 22px rgba(0, 0, 0, 0.051));
    border-radius: 25px 25px 0 0;
    width: 100%;
    justify-content: space-between;
    display: flex;
    display: flex;
    align-items: center;
    background: #FFFFFF;
}

.ride-options .effect {
    background: rgba(228, 233, 242, 1);
    width: 2rem;
    height: 0.3rem;
    position: absolute;
    top: .5rem;
    border-radius: 15px;
    left: 50%;
}

.ride-options .options {
    padding: 0 1rem;
    width: 100%;
}

.ride-options .options .option {
    display: flex;
    width: 100%;
    cursor: pointer;
    height: 4.2rem;
    padding: 1rem;
    align-items: center;
    background-color: rgba(170, 86, 229, 0.05);
    margin-top: 1rem;
    justify-content: space-between;
    border-radius: 15px;
}

.ride-options .options h1 {
    color: rgba(34, 43, 69, 1);
    font-weight: 500;
    font-size: 1.2rem;
    font-family: 'Poppins';
}


.ride-options .options .option div h2 {
    font-family: 'Poppins';
    margin-bottom: 0;
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: uppercase;
    line-height: 22px;
    color: #222B45;
}

.ride-options .options .option div p {
    color: #2d2c31;
    font-weight: 500;
    font-size: 0.8em;
    margin-bottom: 1rem;
    font-family: 'Poppins';
}

.selected-nav {
    background-color: #b9b9b97d !important;
}

@media (min-width: 768px) {
    .ride-options {
        height: 11rem;
        display: flex;
        justify-content: center;
    }

    .ride-options .effect  {
        left: auto;
    }

    .ride-options .options {
        width: 50%;
        display: flex;
        gap: 1rem;
    }


}