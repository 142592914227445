#navmenu {
    width: 100%;
    height: 5.5rem;
    left: 0;
    border-radius: 25px 25px 0 0;
    bottom: 0;
    background-color: #FCFCFD;
}
#navmenu ul {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#navmenu ul li {
    list-style: none;
    margin: 0 1.4rem;
    display: flex;
    font-size:0.9rem;
    font-weight: 500;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
}

#navmenu ul li:not(:first-child) {
    color: #CEC9B9;
    font-weight: normal;
    font-size: 1rem;
}

#navmenu ul li img {
    width: 1.5rem;
}