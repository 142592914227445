#first {
    padding: .5rem 25%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: white;
    background-color: #6356E5;
    border: 1px solid #6356E5;
    border-radius: 1rem 0 0 1rem;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

#second {
    padding: .5rem 25%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: #6356E5;
    background-color: white;
    border: 1px solid #6356E5;
    border-radius: 0 1rem 1rem 0;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

#first:hover {
    background-color: white;
    color: #6356E5;
}

#second:hover {
    background-color: #6356E5;
    color: white;
}