.msg-input {
    border: 2px solid #6356E5;
    color: #6356E5;
    padding: .5rem;
    border-radius: 10px;
    display: flex;
    font-weight: bold;
    font-family: "Poppins";
    font-size: large;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
}
