.back {
    background-color: #6356E5;
    padding: 1rem 1rem;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.back h1 {
    color: white;
    font-weight: 300;
    font-size: 1.5rem;
}

.price_container_candidate {
    color: white;
    display: flex;
    gap: 1.5rem;
}

.btn_action_candidate_first {
    border: 2px solid #6356E5;
    color: #6356E5;
    padding: .5rem;
    border-radius: 10px 0 0 10px;
    display: flex;
    font-weight: bold;
    font-family: "Poppins";
    font-size: large;
    text-align: center;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    transition: all 50ms ease-in-out;
}

.btn_action_candidate_first:hover {
    background-color: #6356E5;
    color: white;
}

.btn_action_candidate_second:hover {
    background-color: #6356E5;
    color: white;
}

.btn_action_candidate_second {
    transition: all 50ms ease-in-out;
    border: 2px solid #6356E5;
    color: #6356E5;
    padding: .5rem;
    border-radius: 0 10px 10px 0;
    display: flex;
    font-weight: bold;
    font-family: "Poppins";
    font-size: large;
    text-align: center;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}