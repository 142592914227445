.owl-dot {
    width: 9px !important;
    height: 9px !important;
    background: rgb(238, 238, 240) !important;
    margin-right: .5rem;
}

#onboarding-btn {
    text-decoration: none;
    color: #222B45;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 4rem;
    padding: 1.2rem 1.6rem 1.2rem 1.6rem;
    border-radius: 15px;
    font-family: 'Poppins';
    box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.039);
}

#onboarding-btn svg {
    margin-left: 3rem;
}

.onboarding-text {
    font-size: 1.2rem;
    font-weight: 300;
    opacity: 1;
    color: black;
    font-family: 'Poppins';
}

.owl-dot.active {
    background: #6356E5 !important;
    margin-right: .5rem;
}