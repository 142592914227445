.fade-enter {
    opacity: 0;
    transform: translateY(-10px);
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
}

.rotated img {
    transform: rotate(180deg);
    transition: transform 300ms;
}

.unrotated img {
    transform: rotate(0deg);
    transition: transform 300ms;
}


#outlined-basic {
    /* margin-top: 1rem; */
    padding-top: 1rem;
}